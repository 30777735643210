import resolveUrl from "./resolve-url";

const resolveLocalfirmUrl = ({ bank, ...localfirm }) => {
  return resolveUrl(localfirm.specific_url) || resolveUrl(
    localfirm.prefix_url || bank?.prefix_url, 
    localfirm.url,
    localfirm.isHeadquarters !== 1 && localfirm.geo_url ? localfirm.geo_url : '',
  );
}

export default resolveLocalfirmUrl;